import React, {Component} from "react";
import ReactDOM from "react-dom";

import {
  AdaptivityProvider,
  ConfigProvider,
  AppRoot,
  Div,
  Avatar,
  Button,
  Spacing,
} from "@vkontakte/vkui";

import "@vkontakte/vkui/dist/vkui.css";

import Typed from 'react-typed';

import SnowStorm from 'react-snowstorm';

const avatar_blur = {
  background: '#b39ddb',
  width: 112,
  height: 112,
  margin: 8,
  position: 'absolute',
  borderRadius: '50%',
  filter: 'blur(15px)'
}

class Snow extends Component {
  render(){
    return (
      <div>
        <SnowStorm
          snowColor='#b39ddb'
          followMouse={false}
          snowCharacter='*'
          vMaxX={1.5}
          vMaxY={1.5}
          flakesMax={26}/>
      </div>
    )
  }
}

const tg = <img alt="telegram icon" src="https://img.icons8.com/color/48/000000/telegram-app--v4.png" width={18} height={17}/>;
const github = <img alt="github icon" src="https://img.icons8.com/ios-glyphs/60/null/github.png" width={18} height={17}/>;

const VkCard = () => {
  return (
    <ConfigProvider scheme="space_gray">
      <AdaptivityProvider>
        <AppRoot>
          <Div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', textAlign: 'center', margin: 0}}>
            <span>
              <div style={avatar_blur}></div>
              <Avatar alt="Avatar" src="https://i.imgur.com/E2h4Fer.jpeg" size={128} badge="online"/>
            </span>
            <h1 className="vkuiTitle vkuiTitle--android Title Title--android vkuiTitle--w-semibold Title--w-semibold vkuiTitle--l-1 Title--l-1"
              style={{marginTop: 32}}>
              Никита Тимофеев
            </h1>
            <div>
              Я &nbsp;
              <Typed
                strings={['никита', 'программист', 'лицеист', 'знаю С++', 'знаю Python', 'люблю пообщаться', 'люблю CTF', 'пентестер', 'люблю писать ботов', 'morisummer']}
                typeSpeed={60}
                showCursor={true}
                backSpeed={50}
                smartBackspace={false}
                backDelay={1000}
                loop={true}
                shuffle={true}
                cursorChar="_"
                style={{color: '#b39ddb'}}
              />
            </div>
            <Spacing size={16} />
            <b>Innopolis, Russia</b>
            <Spacing size={16}/>
            <Button
                before={tg}
                appearance='neutral'
                size='m'
                style={{background: "#fefefe", color: "#121212", margin: 8}}
                href="tg://resolve?domain=morisummer"
                target="_blank">
                Telegram
            </Button>
            <Button
                before={github}
                appearance='neutral'
                size='m'
                style={{background: "#fefefe", color: "#121212", margin: 8}}
                href="https://github.com/MoriSummerz"
                target="_blank">
                GitHub
            </Button>
          </Div>
        </AppRoot>
      </AdaptivityProvider>
    </ConfigProvider>

  );
};

ReactDOM.render(
  <div>
    <VkCard/>
    <Snow/>
  </div>,
  document.getElementById("root")
);